import React, { useRef, useEffect, useState } from "react";
import './App.css';


// Components
import HeaderComponent from './components/HeaderComponent';
import FooterComponent from './components/FooterComponent';
import MarqueeComponent from './components/MarqueeComponent';

// Router 
import { NavLink } from "react-router-dom";

// Images
import ImgMain from "./assets/poke-bowl-ver-main.jpg";
import ImgHor from "./assets/poke-bowl-inicio-main.jpg";
import ImgVer2 from "./assets/poke-bowl-ver-2.jpg";
import ImgHor2 from "./assets/poke-bowl-hor-2.jpg";
import ImgVer3 from "./assets/poke-bowl-ver-3.jpg";
import ImgHor3 from "./assets/poke-bowl-hor-3.jpg";

function App() {

  return (
    <div className="App">
      <HeaderComponent />
      <main className='bg-pink'>
        <div className='inicio-main container'>
          <div className="inicio-main-col-1">
            <h1>¡Bienvenido al paraíso de Poke Bowls!</h1>
            <p className="desc">Descubre una experiencia culinaria extraordinaria en nuestro restaurante, donde los sabores tropicales se fusionan en cada Poke Bowl. Con ingredientes frescos y selección cuidadosa, cada bocado es una explosión de sabores exquisitos y texturas vibrantes.</p>
          </div>
          <div className="main-col-2">
            <img src={ImgMain} alt='Poke Bowl' className="index-main-poke" />
            <div className='btn-black btn-1'>
              <p className='pink'>Combinación de sabores únicas</p>
            </div>
            <div className='btn-black btn-2'>
              <p className='pink'>Entrega a domicilio y pedidos en línea</p>
            </div>
          </div>
        </div>
      </main>
      <MarqueeComponent />
      <section className='section-1'>
        <div className='container'>
          <p>Sumérgete en el mundo <span className='img-1'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span> de los sabores frescos y saludables de nuestros poke bowls. Nuestra propuesta se basa en ingredientes de calidad, combinados a la perfección <span className="img-2">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span> para brindarte una experiencia única. ¡Ven y descubre por qué nuestros clientes no <span className="img-3">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span> pueden resistirse a nuestros deliciosos poke bowls!</p>
        </div>
      </section>
      <section className='section-2'>
        <div className="container">
          <div className='text-container bg-pink'>
            <h2 className='text-center'>Conoce Nuestros Bowls</h2>
            <p className='text-center'>Nuestros poke bowls están cuidadosamente elaborados para aquellos amantes de la comida saludable y sostenible con los mejores sabores tradicionales que nunca pasan de moda.</p>
          </div>
          <div className="grid grid-4">
            <div className="col">
              <img src={ImgMain} className="grid-4-img" alt="" />
              <h3 className='text-center'>Tropical Bliss</h3>
            </div>
            <div className="col">
              <img src={ImgVer2} className="grid-4-img" alt="" />
              <h3 className='text-center'>Hawaiian Heat</h3>
            </div>
            <div className="col">
              <img src={ImgVer3} className="grid-4-img" alt="" />
              <h3 className='text-center'>Samurai Bowl</h3>
            </div>
            <div className="col">
              <img src={ImgMain} className="grid-4-img" alt="" />
              <h3 className='text-center'>Sunrise Paradise</h3>
            </div>
          </div>
          <div className="text-center">
            <NavLink to="/sobre-nosotros" className="pink btn">Visita nuestro menú</NavLink>
          </div>
        </div>
      </section>
      <section className='section-3 pink flex' style={{ position: 'relative', overflow: 'hidden' }}>
        <h2 className='text-center'>Infinitud de Opciones</h2>
        <p className='text-center'>Personaliza tu poke bowl <span className="img-2-1">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span> a tu gusto.  Elige tu base, proteínas, ingredientes frescos y aderezos <span className="img-2-2">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span> para crear una combinación única  y deliciosa según tus preferencias. ¡Disfruta de una experiencia gastronómica <span className="img-2-3">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span> a tu medida!</p>
        <div className="">
          <NavLink to="/menu" className="btn-pink">Todos los  productos</NavLink>
        </div>
      </section>
      <section className='section-4 bg-pink'>
        <div className='section-container container'>
          <div> <h2>Combinaciones únicas de sabores</h2>
            <p>Nos enorgullece ofrecerte una experiencia culinaria excepcional con ingredientes frescos y una atención meticulosa a los detalles.</p>
            <div className="btn-black">
              <NavLink to="/sobre-nosotros" className="pink">Sobre nosotros</NavLink>
            </div>
          </div>
          <div className="section-4-col">
            <img src={ImgMain} className="section-4-col-img" />
            <img src={ImgVer2} className="section-4-col-img" />
          </div>
        </div>
      </section>
      <MarqueeComponent />
      <FooterComponent />
    </div>
  );
}

export default App;
