import React from "react";

// Components
import HeaderComponent from "../components/HeaderComponent";
import FooterComponent from "../components/FooterComponent";
import MarqueeComponent from "../components/MarqueeComponent";

import ImgVer from "../assets/poke-bowl-ver-main.jpg";
import ImgHor from "../assets/poke-bowl-inicio-main.jpg";
import ImgVer2 from "../assets/poke-bowl-ver-2.jpg";
import ImgHor2 from "../assets/poke-bowl-hor-2.jpg";
import ImgVer3 from "../assets/poke-bowl-ver-3.jpg";
import ImgHor3 from "../assets/poke-bowl-hor-3.jpg";


// Import Router
import { NavLink } from "react-router-dom";


const SobreNosotros = () => {
  return (
    <>
      <HeaderComponent />
      <main className="bg-pink">
        <div className="container">
          <div className="main-sobre">
          <h1 className="text-center">Poke Bowl Paradise</h1>
          <p className="sobre">
            Descubre una experiencia culinaria extraordinaria en nuestro
            restaurante, donde los sabores tropicales se fusionan en cada Poke
            Bowl.
          </p>
          <div className="main-col-2-sobre">
          <img src={ImgHor} alt='Poke Bowl' className="sobre-main-poke" />
            <div className="btn-black btn-1">
              <p className="pink">Ingredientes frescos y de calidad</p>
            </div>
            <div className="btn-black btn-2">
              <p className="pink">Enfoque en la salud y el bienestar</p>
            </div>
          </div>
        </div>
        </div>
      </main>
      <MarqueeComponent />
      <section className="bg-pink">
        <div className="section-1-sobre container">
          <div>
            <h2>Nuestra historia</h2>
            <p className="text">
              Poke Bowls Paradise nació de la pasión por la cocina y los sabores
              auténticos. Inspirados por la magia de los poke bowls en Hawái,
              decidimos compartir esa experiencia culinaria excepcional con
              nuestra comunidad. Con ingredientes frescos y una atención
              meticulosa a los detalles, creamos un paraíso de poke bowls donde
              cada bocado es una explosión de sabores vibrantes.
            </p>
            <div className="">
            <NavLink to="/sobre-nosotros" className="pink btn-black ">Visita nuestro menú</NavLink>
          </div>
          </div>
          <div>
            <img src={ImgVer} alt="Poke Bowl" className="img-border" />
          </div>
        </div>
        </section>
        <section className="bg-white">
          <div className="section-2-sobre container ">
            <div className="col-1">
            <img src={ImgVer2} alt="Poke Bowl" className="img-border" />
            </div>
            <div className="col-2">
              <h2>Nuestros Valores</h2>
              <div className="container-valors">
                <h3 className="title-valors">Calidad</h3>
                <p>
                  Nos comprometemos a utilizar ingredientes frescos y de la más
                  alta calidad en cada uno de nuestros poke bowls. Desde las
                  proteínas hasta los acompañamientos.
                </p>
              </div>
              <div className="container-valors">
                <h3 className="title-valors">Sostenibilidad</h3>
                <p>
                  Nos preocupamos por el bienestar del planeta y nos esforzamos
                  por ser un negocio sostenible. Utilizamos envases ecoamigables
                  y colaboramos con proveedores locales para minimizar nuestro
                  impacto ambiental.
                </p>
              </div>
              <div className="container-valors">
                <h3 className="title-valors">Pasión</h3>
                <p>
                  Nos apasiona la comida y la cultura que rodea a los poke
                  bowls. Ponemos todo nuestro corazón en cada creación para
                  ofrecerte una experiencia culinaria excepcional.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className='section-4 bg-pink'>
        <div className='section-container container'>
          <div> <h2>Personalización y creatividad</h2>
            <p>En Poke Bowls Paradise creamos poke bowls personalizados y llenos de creatividad para brindarte una experiencia culinaria única. ¡Ven y descubre combinaciones sorprendentes y deliciosas adaptadas a tus gustos!</p>
            <div className="btn-black">
              <NavLink to="/menu" className="pink">Visita nuestro menú</NavLink>
            </div>
          </div>
          <div className="section-4-col">
            <img src={ImgVer2} className="section-4-col-img" />
            <img src={ImgVer} className="section-4-col-img" />
          </div>
        </div>
      </section>
      <MarqueeComponent />
      <FooterComponent />
    </>
  );
};

export default SobreNosotros;
