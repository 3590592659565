import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import SobreNosotros from "./pages/SobreNosotros.jsx";
import Menu from "./pages/Menu.jsx";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "sobre-nosotros",
    element: <SobreNosotros />,
  },
  {
    path: "menu",
    element: <Menu />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
