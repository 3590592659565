import React, { useState} from "react";
import { NavLink } from "react-router-dom";


const HeaderComponent = () => {
  const [open, setOpen] = useState(false);

  return (
    <div className="header-container bg-pink">
      <header className="header container">
        <div>
          <NavLink to="/">
            <p className="logo">Poke Bowl Paradise</p>
          </NavLink>
        </div>
        <div>
          <nav className="flex">
            <NavLink to="/">Inicio</NavLink>
            <NavLink to="/sobre-nosotros">Sobre nosotros</NavLink>
            <NavLink to="/menu">Menú</NavLink>
          </nav>
        </div>
        <div className="container-menu-trigger">
            <div className="pink menu-trigger" onMouseEnter={() => setOpen(!open)} onMouseLeave={() => setOpen(false)}>
            <div className="btn-black">
              <p>Pide Ahora</p>
              </div>
            <div className={`dropdown-menu ${open ? 'active' : 'inactive'}`}>
              <ul>
                <li className="dropdown-item"><a href="https://www.just-eat.es/"  target="_blank" rel="noreferrer">Just Eat</a></li>
                <li className="dropdown-item"><a href="https://www.ubereats.com/es" target="_blank"  rel="noreferrer">Uber Eats</a></li>
              </ul>
            </div>
          </div>
          </div>
      </header>
    </div>
  );
};

export default HeaderComponent;
