import React from "react";

// Components
import HeaderComponent from "../components/HeaderComponent";
import FooterComponent from "../components/FooterComponent";
import MarqueeComponent from "../components/MarqueeComponent";

import ImgVer from "../assets/poke-bowl-ver-main.jpg";
import ImgHor from "../assets/poke-bowl-inicio-main.jpg";
import ImgVer2 from "../assets/poke-bowl-ver-2.jpg";
import ImgHor2 from "../assets/poke-bowl-hor-2.jpg";
import ImgVer3 from "../assets/poke-bowl-ver-3.jpg";
import ImgHor3 from "../assets/poke-bowl-hor-3.jpg";

const Menu = () => {

  return (
    <div>
      <HeaderComponent />
      <main className="bg-pink">
        <div className="container">
          <div className="main-menu">
          <h1 className="text-center">Nuestro Menú</h1>
          <p className="sobre">
            Descubre una experiencia culinaria extraordinaria en nuestro
            restaurante, donde los sabores tropicales se fusionan en cada Poke
            Bowl.
          </p>
          <div className="main-col-2-menu">
          <img src={ImgHor} alt='Poke Bowl' className="menu-main-poke" />
            <div className="btn-black btn-1">
              <p className="pink">Bowl Mediano<br/> 9,95€</p>
            </div>
            <div className="btn-black btn-2">
              <p className="pink">Bowl Grande<br/>  11,95€</p>
            </div>
          </div>
        </div>
        </div>
      </main>
      <MarqueeComponent />
      <section>
        <div className="section-1-menu container">
          <h2 className="text-center">Poke Bowls</h2>
          <p className="text-center ch">
            Nuestra selección de bowls clásicos de poke, cuidadosamente
            elaborados para aquellos amantes de los sabores tradicionales que
            nunca pasan de moda.
          </p>
          <div className="poke-bowl-container grid grid-3 text-center">
            <div>
              <img src={ImgHor} alt=""/>
              <h3>Tropical Bliss</h3>
              <p>Salmón fresco - Mango - Pepino - Aguacate - Arroz de sushi</p>
            </div>
            <div>
              <img src={ImgHor2} alt="" />
              <h3>Hawaiian Heat</h3>
              <p>Atún picante - Mango - Pepino - Cebolla - Arroz blanco</p>
            </div>
            <div>
              <img src={ImgHor3} alt="" />
              <h3>Samurai Bowl</h3>
              <p>
                Salmón teriyaki - Aguacate - Edamame - Rábano - Arroz de sushi
              </p>
            </div>
            <div>
              <img src={ImgHor3} alt="" />
              <h3>Sunrise Paradise</h3>
              <p>
                Salmón ahumado - Mango - Cebolla morada - Aguacate - Arroz
                integral
              </p>
            </div>
            <div>
              <img src={ImgHor2} alt="" />
              <h3>Island Paradise</h3>
              <p>
                Salmón fresco - Mango - Pepino - Algas marinas - Cebolla - Arroz
                de sushi
              </p>
            </div>
            <div>
              <img src={ImgHor} alt="" />
              <h3>Hawaiian Heat</h3>
              <p>Atún picante - Mango - Pepino - Cebolla - Arroz blanco</p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="section-2-menu container">
          <h2 className="text-center">Personaliza Tu Bowl</h2>
          <p className="subtitle">
            Crea un bowl de poke único y delicioso que se adapte a tus
            preferencias y antojos. ¡Exprésate a través de tus propias
            combinaciones y descubre nuevas y emocionantes mezclas de sabores!
          </p>
          <div className="personaliza-tu-bowl">
            <div className="col-1">
              <img src={ImgVer} className="img-border"></img>
              <div className="btn-black btn-1">
                <p className="pink">Bowl Mediano</p>
                <p className="pink">10.95</p>
              </div>
              <div className="btn-black btn-2">
              <p className="pink">Bowl Grande</p>
                <p className="pink">12.95</p>
              </div>
            </div>
            <div className="col-2">
                <div>
                    <h3 className="title-alimentos">Protéinas</h3>
                    <ul className="lista-alimentos">
                        <li>Salmón fresco</li>
                        <li>Camarones</li>
                        <li>Atún fresco</li>
                        <li>Pulpo</li>
                        <li>Tofu marinado</li>
                    </ul>
                </div>
                <div>
                    <h3 className="title-alimentos">Bases</h3>
                    <ul className="lista-alimentos">
                        <li>Arroz de sushi</li>
                        <li>Arroz integral</li>
                        <li>Quinoa</li>
                        <li>Hojas verdes mixtas</li>
                        <li>Espinacas frescas</li>
                        <li>Lechuga romana</li>
                    </ul>
                </div>
                <div>
                    <h3 className="title-alimentos">Vegetales y frutas</h3>
                    <ul className="lista-alimentos">
                        <li>Pepino</li>
                        <li>Zanahorias ralladas</li>
                        <li>Edamame</li>
                        <li>Mango</li>
                        <li>Aguacate</li>
                        <li>Piña fresca</li>
                        <li>Cebolla</li>
                        <li>Cebolla morada</li>
                    </ul>
                </div>
                <div>
                    <h3 className="title-alimentos">Topings y Aderezos</h3>
                    <ul className="lista-alimentos">
                        <li>Algas marinas</li>
                        <li>Semillas de sésamo</li>
                        <li>Semillas de chía</li>
                        <li>Salsa picante</li>
                        <li>Salsa de soja baja en sodio</li>
                    </ul>
                </div>
                <div>
                    <h3 className="title-alimentos">Bebidas</h3>
                    <ul className="lista-alimentos">
                        <li>Agua</li>
                        <li>Refrescos</li>
                        <li>Cerveza</li>
                        <li>Zumos naturales</li>
                        <li>Kombuchas</li>
                    </ul>
                </div>
            </div>
          </div>
        </div>
      </section>
      <MarqueeComponent />
      <FooterComponent />
    </div>
  );
};

export default Menu;
