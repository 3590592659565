import React from "react";
import { NavLink } from "react-router-dom";

const FooterComponent = () => {
  return (
    <footer className="footer bg-pink">
      <div className="container">
        <div className="border-black"></div>
        <div className="footer-container">
          <div className="col-1">
            <p className="logo">Poke Bowl Paradise</p>
          </div>
          <div className="col-2">
            <p className="footer-title">Localización</p>
            <p className="footer-text">Barcelona</p>
            <p className="footer-text">Gran de Sant Andreu, 20</p>
          </div>
          <div className="col-3">
            <p className="footer-title">Horario</p>
            <p className="footer-text">Lun-Vie: 12:00 - 22:30</p>
            <p className="footer-text">Lun-Vie: 12:00 - 22:30</p>
          </div>
          <div className="col-4">
            <p className="footer-title">Contacto</p>
            <p className="footer-text">Teléfono</p>
            <p className="footer-text">932 08 70 18</p>
            <br />
            <p className="footer-text">Email</p>
            <p className="footer-text">info@pbw.com</p>
          </div>
        </div>
        <div className="border-black"></div>
        <div className="second-footer-container">
            <div>
                <p>2023 © Poke Bowl Paradise | Todos los derechos reservados</p>
            </div>
            <div className="second-links-container">
                <NavLink to="/">Aviso legal</NavLink>
                <NavLink to="/">Política de privacidad</NavLink>
            </div>
        </div>  
      </div>
    </footer>
  );
};

export default FooterComponent;
