import React from 'react';


const MarqueeComponent = () => {
    return (
        <div className='marquee'>
            <div>
                <span>Poke Bowl Paradise</span>
                <span>Poke Bowl Paradise</span>
                <span>Poke Bowl Paradise</span>
            </div>
            <div>
                <span>Poke Bowl Paradise</span>
                <span>Poke Bowl Paradise</span>
                <span>Poke Bowl Paradise</span>
            </div>
        </div>
    );
};

export default MarqueeComponent;